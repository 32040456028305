<template>
  <div class="contact_us">
    <div class="con_nav">
      <nav-tab :nav-data="navData" />
    </div>
    <div class="con_center">
      <JoinContent />
    </div>
    <div class="con_bottom">
      <layout-bottom />
    </div>
  </div>
</template>

<script>
import NavTab from '@/components/NavTab'
import LayoutBottom from '@/components/LayoutBottom'
import JoinContent from './components/joinContent.vue'
import { getNavData } from '@/utils/navData'
export default {
  components: {
    NavTab,
    JoinContent,
    LayoutBottom
  },
  data() {
    return {
      navData: getNavData(2)
    }
  }

}
</script>

<style lang="scss" scoped>
// @media only screen and (min-width: 768px){
//   .con_center{
//     padding-top: 60px;
//   }
// }
@media only screen and (max-width: 767px){
  .con_nav{
    height: 150px;
    background: #fff;
  }
}

</style>
